//@ts-ignore
import React, { FunctionComponent, useEffect, useRef } from 'react';

const Modal: FunctionComponent<{ closeModal: () => void; isOpen: boolean }> = ({ closeModal, isOpen, children }) => {
  const node = useRef() as React.MutableRefObject<HTMLInputElement>;
  // const nodeRef = useRef(null);

  const closeOnClickOut = (e: MouseEvent) => {
    // @ts-ignore
    if (node.current && node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    closeModal();
  };
  const closeOnEscape = (event: KeyboardEvent) => {
    if (event.code === 'Escape') {
      closeModal();
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', closeOnClickOut);
    document.addEventListener('keydown', closeOnEscape, false);
    return () => {
      document.removeEventListener('mousedown', closeOnClickOut);
      document.removeEventListener('keydown', closeOnEscape, false);
    };
  }, []);

  return (
    <div className="bg-opacity-60 bg-black fixed z-50 w-full h-full left-0 top-0 flex justify-center items-center">
      {/*fixme: why aren't these working? i do not understand */}
      {/*<CSSTransition in={isOpen} nodeRef={node} timeout={500} unmountOnExit mountOnEnter classNames="modal">*/}
        <div ref={node} className="relative">
          <button className="block w-6 absolute right-1 top-8 transform -translate-x-1/2 -translate-y-1/2 text-white"
                  onClick={() => closeModal()}
                  aria-label="Close">
            <span aria-hidden="true"
                  className="block absolute h-0.5 w-4 bg-current transform transition duration-500 ease-in-out rotate-45"/>
            <span aria-hidden="true"
                  className="block absolute h-0.5 w-4 bg-current transform  transition duration-500 ease-in-out -rotate-45"/>
          </button>
          {children}
        </div>
      {/*</CSSTransition>*/}
    </div>
  );
};

export default Modal;

import React from 'react'
import {SubmitButton} from "./buttons";
import {P} from "./typography";

// netlify form taken from here:
// https://github.com/sw-yx/gatsby-netlify-form-example-v2/blob/master/src/pages/contact.js
// https://github.com/netlify-templates/gatsby-starter-netlify-cms/blob/master/src/pages/contact/index.js

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}


const NewsletterForm = () => {
  const [state, setState] = React.useState({})
  const [submitted, setSubmitted] = React.useState(false);

  const handleChange = (e) => {
    setState({ [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => {
        setSubmitted(true)
      })
      .catch((error) => alert(error))
  }

  if (submitted) {
    return <div>
      <P className="text-white py-4">Thank you for signing up for the Black Joy Newsletter!</P>
    </div>
  }

  return (
    <form
      name="newsletter"
      method="post"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
    >
      {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
      <input type="hidden" name="form-name" value="contact" />
      <div hidden>
        <label>
          Don’t fill this out:{' '}
          <input name="bot-field" onChange={handleChange} />
        </label>
      </div>
      <div className="field">
        <label className="hidden" htmlFor={'email'}>
          Email
        </label>
        <div>
          <input type="email"
                 placeholder="Enter email here"
                 name="email"
                 id="email"
                 required={true}
                 onChange={handleChange}
                 className="w-full py-2 pl-2 my-12 text-black focus:outline-none"/>
        </div>
      </div>
      <div className="text-center">
        <SubmitButton className="w-20 text-bold">Subscribe</SubmitButton>
      </div>
    </form>
  )
}

export default NewsletterForm;

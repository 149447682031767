import React, {useEffect, useState} from 'react'
import Navigation from './navigation'
import Footer from "./footer";
import Modal from "./modal";
import NewsletterForm from "./newsletter-form";
import {LGHeader} from "./typography";
import { useLocation } from "@reach/router"
import MarketingBanner from "./marketing-banner";

const Layout = ({children}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const location = useLocation();

  useEffect(() => {
    const now = Date.now();
    const newsletterModal = window.localStorage.getItem('newsletterModal')
    if (!newsletterModal || parseInt(newsletterModal) < now) {
      setModalOpen(true)
      const thirtyDaysLater = (30 * 24 * 60 * 60 * 1000) + now;
      window.localStorage.setItem('newsletterModal', thirtyDaysLater.toString())
    }
  }, [setModalOpen])

  useEffect(() => {
    if (document.getElementById('commitchange-script')) {
      let script = document.getElementById('commitchange-script');
      script.remove();
    };
    let npo = 5794;
    let script = document.createElement('script');
    let first = document.getElementsByTagName('script')[0];
    script.setAttribute('data-npo-id', npo);
    script.id = 'commitchange-script';
    script.src = 'https://us.commitchange.com/js/donate-button.v2.js';
    first.parentNode.insertBefore(script, first);
  }, [location.pathname])


  return (
    <>
      <MarketingBanner/>
      <div className="bg-gray bg-texture relative">
        <Navigation/>
        <div className="pt-20">
          <main>
            {children}
          </main>
          <Footer/>
        </div>
        {modalOpen && (
          <Modal closeModal={() => setModalOpen(false)} isOpen={modalOpen}>
            <div className="bg-gray bg-texture border-2 border-gray-200 p-4 m-4 text-center">
              <LGHeader>Join our Newsletter</LGHeader>
              <NewsletterForm />
            </div>
          </Modal>
        )}
      </div>
    </>
  )
}

export default Layout;

import React, {useEffect, useState} from "react";
import {graphql, Link, useStaticQuery} from "gatsby";
import get from "lodash/get";

const MarketingBanner = () => {
  const data = useStaticQuery(graphql`
    query BannerQuery {
      contentfulHeaderNavigation(contentful_id: {eq: "1C6Qlai2d8Oob1U3phEE1L"}) {
        marketingBanner {
          ... on ContentfulMarketingBanner {
            title
            link
            linkType
          }
        }
      }
    }
  `)

  const bannerData = get(data, 'contentfulHeaderNavigation.marketingBanner')
  const {title, link, linkType} = bannerData

  const [bannerOpen, setBannerOpen] = useState(false);
  const now = Date.now();

  useEffect(() => {
    const marketingBanner = window.localStorage.getItem('marketingBanner')
    if (!marketingBanner || parseInt(marketingBanner) < now) {
      setBannerOpen(true)
    }
  }, [setBannerOpen, now])

  if (!bannerData) {
    return null
  }

  const closeBanner = () => {
    setBannerOpen(false)
    const thirtyDaysLater = (30 * 24 * 60 * 60 * 1000) + now;
    window.localStorage.setItem('marketingBanner', thirtyDaysLater.toString())
  }
  const titleElem = <div className="text-center">
    <h2 className="font-button uppercase tracking-wide py-4">{title}</h2>
  </div>

  return (
    <div className={`overflow-hidden transition-all duration-500 ease-out bg-yellow w-full text-center px-4 ${!bannerOpen ? 'invisible max-h-0' : 'visible max-h-24'}`}>
      <div className="grid grid-cols-12">
        {linkType === 'Internal' ?
          <Link to={link} onClick={closeBanner} className="col-span-1"/>:
          <a href={link} target="_blank" rel="noreferrer" onClick={closeBanner} className="col-span-2"/>
        }
        {linkType === 'Internal' ?
          <Link to={link} onClick={closeBanner} className="col-span-10">{titleElem}</Link>:
          <a href={link} target="_blank" rel="noreferrer" onClick={closeBanner} className="col-span-8">{titleElem}</a>
        }
        <div className="col-span-1 cursor-pointer" onClick={(e) => {e.preventDefault(); closeBanner();}}>
          <div className="flex justify-end h-full w-full pr-4">
            <button className="text-black"
                    onClick={(e) => {e.preventDefault(); closeBanner();}}
                    aria-label="Close">
                  <span aria-hidden="true"
                        className="block absolute h-0.5 w-4 bg-current transform rotate-45"/>
              <span aria-hidden="true"
                    className="block absolute h-0.5 w-4 bg-current transform -rotate-45"/>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MarketingBanner;

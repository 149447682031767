//@ts-ignore
import React, {FC} from "react";

export const XLHeader:FC<{white?: boolean, className?: string}> = ({children, white, className}) => (
    <h1 className={`font-heading font-bold text-8xl md:text-9xl uppercase tracking-wide ${className || ''}`}>
        {children}
    </h1>
)

export const LGGHeader:FC<{className?: string}> = ({children, className}) => (
  <h2 className={`font-heading font-bold text-7xl md:text-8xl uppercase tracking-wide text-white ${className || ''}`}>
    {children}
  </h2>
)

export const LGHeader:FC<{className?: string}> = ({children, className}) => (
    <h2 className={`font-heading font-bold text-6xl md:text-7xl uppercase tracking-wide text-white ${className || ''}`}>
        {children}
    </h2>
)

export const MDHeader:FC<{className?: string}> = ({children, className}) => (
  <h2 className={`font-heading font-bold text-5xl uppercase tracking-wide ${className || ''}`}>
    {children}
  </h2>
)

export const SmHeader:FC<{className?: string; noPadding?: boolean; onClick?: any}> = ({children, className, noPadding, onClick}) => (
    <h2 className={`font-button text-2xl uppercase tracking-wide ${noPadding ? '' : 'pb-4'} ${className || ''}`} onClick={onClick}>
        {children}
    </h2>
)

export const XSHeader:FC<{className?: string; noPadding?: boolean; onClick?: any}> = ({children, className, noPadding, onClick}) => (
  <h2 className={`font-button text-xl uppercase tracking-wide ${noPadding ? '' : 'pb-4'} ${className || ''}`} onClick={onClick}>
    {children}
  </h2>
)

/**
 * MarkdownContainer for holding the rendered markdown from contentful
 * @param children: must be of string type - the rendered markdown html
 * @param className: classes that will be added to the container
 * @constructor
 */
export const MarkdownContainer:FC<{className?: string;useSourceSans?: boolean;}> = ({children, className, useSourceSans}) => (
    <div
        className={`markdown font-body text-xl ${className || ''} ${useSourceSans ? 'use-source-sans-pro' : ''}`}
        dangerouslySetInnerHTML={{
            __html: children as string,
        }}
    />
)

export const P:FC<{className: string}> = ({children, className}) => (
    <p className={`font-body text-xl ${className ? className : 'text-white'}`}>
        {children}
    </p>
)

import React from "react"
// import {useStaticQuery, graphql} from "gatsby"
import {LGHeader} from "./typography";
import NewsletterForm from "./newsletter-form";

const Footer = () => {
  // const data = useStaticQuery(graphql`
  //   query FooterQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)
  const ImageLink = ({href, src, alt}) => <a className="h-full" href={href} target="_blank" rel="noreferrer">
    <img className="px-2 h-full" src={src} alt={alt}/>
  </a>;

  const currentYear = new Date().getFullYear();

  return (
    <footer className="border-t-2 border-gray-200 px-8 md:px-16 pt-8 md:pt-16 w-full text-white">
      <div className="max-w-md mx-auto text-center pb-4">
        <LGHeader>Join our Newsletter</LGHeader>
        <NewsletterForm />
      </div>
      <div className="my-4 flex flex-col md:flex-row justify-between items-center md:items-end">
        <div className="flex h-6 mb-6 md:mb-1">
          <ImageLink href="http://instagram.com/kingcountyequitynow" src="/imgs/ig.png" alt="instagram logo" />
          <ImageLink href="https://www.facebook.com/KingCountyEquityNow/" src="/imgs/facebook.png" alt="facebook logo" />
          <ImageLink href="https://twitter.com/KCEquityNow" src="/imgs/twitter.png" alt="twitter logo" />
          <ImageLink href="mailto:tapin@kingcountyequitynow.com" src="/imgs/white-envelope.png" alt="envelope" />
        </div>
        <div className="flex flex-col text-center md:text-right">
          <span>1143 Martin Luther King Jr. Way #78</span>
          <span>Seattle, WA 98122</span>
          <a href="mailto:tapin@kingcountyequitynow.com" className="text-yellow underline">tapin@kingcountyequitynow.com</a>
          <span>{`© ${currentYear} King County Equity Now`}</span>
        </div>
      </div>
    </footer>
  )
}

export default Footer;
